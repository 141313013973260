export default theme => ({
  root: {
    width: 161,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    position: 'relative',
    zIndex: 1,
    appearance: 'none',
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    margin: `0 0 16px 0`,
    '&:focus': {
      outline: 'none'
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 80,
      left: 0,
      width: 90,
      height: 1,
      backgroundColor: theme.grey6,
      zIndex: -1
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 80,
      right: 0,
      width: 80,
      height: 1,
      backgroundColor: theme.grey6,
      zIndex: -1
    }
  },
  rootOnly: {
    width: 126
  },
  rootFirst: {
    width: 126,
    '&::before': {
      width: 0
    }
  },
  top: {
    width: 126,
    opacity: 0.6,
    transition: 'opacity 200ms linear',
    '&[data-is-active=true]': {
      opacity: 1
    },
    'a:hover &': {
      opacity: 0.8
    }
  },
  topActive: {
    height: 14,
    width: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden'
  },
  topActiveIcon: {
    display: 'block',
    height: 10,
    width: 10,
    fill: theme.grey1,
    transform: 'translateY(-100%)',
    transition: 'transform 200ms ease',
    '&[data-is-active=true]': {
      transform: 'translateY(0)'
    }
  },
  topCount: {
    fontSize: 9,
    fontWeight: 400,
    color: theme.grey1,
    textAlign: 'center',
    lineHeight: '14px',
    position: 'relative',
    top: 3,
    paddingTop: 3,
    paddingBottom: 3
  },
  topWhat: {
    fontSize: 11,
    fontWeight: 700,
    color: theme.grey1,
    textAlign: 'center',
    lineHeight: '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  middle: {
    display: 'flex',
    position: 'relative',
    marginBottom: 2
  },
  middleInterval: {
    position: 'relative',
    zIndex: 1,
    top: 2,
    width: 35,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  interval: {
    flexShrink: 0,
    fontSize: 9,
    fontWeight: 400,
    backgroundColor: '#ffffff',
    color: theme.grey3,
    maxWidth: 35,
    paddingLeft: 2,
    paddingRight: 2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  bottomAvatar: {
    position: 'relative',
    zIndex: 1,
    width: 126,
    flexShrink: 0
  },
  avatarContainer: {
    width: 36,
    height: 32,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ffffff',
    '& > svg': {
      opacity: 0.6,
      transition: 'opacity 200ms linear'
    },
    '&[data-is-active=true] > svg': {
      opacity: 1
    },
    'a:hover & > svg': {
      opacity: 0.8
    }
  },
  bottom: {
    width: 126,
    opacity: 0.6,
    transition: 'opacity 200ms linear',
    '&[data-is-active=true]': {
      opacity: 1
    },
    'a:hover &': {
      opacity: 0.8
    }
  },
  bottomWhen: {
    fontSize: 10,
    fontWeight: 400,
    color: theme.grey1,
    lineHeight: '14px',
    textAlign: 'center'
  },
  bottomWho: {
    fontSize: 11,
    fontWeight: 700,
    color: theme.grey1,
    textAlign: 'center',
    lineHeight: '14px',
    overflow: 'hidden',
    width: '100%',
    paddingBottom: 10,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  middleAction: {
    height: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'grid',
    gridTemplateColumns: '42px 38px 42px',
    gridColumnGap: '6px',
    alignItems: 'center'
  },
  referencedBy: {
    gridColumn: '1/2'
  },
  actionIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 45,
    height: 36,
    padding: '0 5px',
    color: theme.grey3,
    gridColumn: '2/3',
    background: theme.white1,
    '&[data-is-last=true]': {
      color: theme.grey1
    },
    '&[data-is-active=true]': {
      color: theme.grey1
    }
  },
  references: {
    gridColumn: '3/4'
  },
  referenceText: {
    fontSize: 12
  },
  referenceIcon: {
    height: 18,
    width: 18
  },
  numberOfAnswers: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '8px',
    '& span': {
      fontSize: 10,
      color: theme.grey2
    }
  }
});
