import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { AvatarGroup } from '@stratumn/atomic';

import DynamicIcon from 'components/ui/dynamicIcon';

import { shortFormat } from 'utils/dates';

import { countFn as count } from '../count';

import styles from './segment.style';

export class Segment extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    interval: PropTypes.string,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    answersCount: PropTypes.number
  };

  static defaultProps = {
    isActive: false,
    interval: null,
    isFirst: false,
    isLast: false,
    answersCount: 0
  };

  renderActionIcon = () => {
    const { classes, link, isLast, isActive } = this.props;
    const icon = (link.action && link.action.icon) || 'Doc';

    return (
      <div
        className={classes.actionIcon}
        data-is-last={isLast}
        data-is-active={isActive}
      >
        <DynamicIcon icon={icon} size={35} />
      </div>
    );
  };

  renderInterval = () => {
    const { classes, interval } = this.props;
    if (!interval) return null;
    return <div className={classes.interval}>{interval}</div>;
  };

  renderWhat = () => {
    const {
      link: { action, actionKey, referencedBy, references }
    } = this.props;
    const what = action ? action.title : actionKey;
    const postfix =
      !!referencedBy.totalCount || !!references.totalCount ? '*' : '';
    return `${what.slice(0, 35).trim()}${what.slice(35) && '…'}${postfix}`;
  };

  renderActiveIcon = () => {
    const { classes, isActive } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        aria-hidden="true"
        className={classes.topActiveIcon}
        data-is-active={isActive}
      >
        <path d="M50 89.489L0 0h100L50 89.489z" />
      </svg>
    );
  };

  render = () => {
    const {
      link,
      classes,
      isActive,
      isFirst,
      isLast,
      answersCount
    } = this.props;
    const { group } = link;
    const hasAnswer = !!answersCount;
    return (
      <div
        className={classnames(
          classes.root,
          { [classes.rootOnly]: isFirst && isLast },
          { [classes.rootFirst]: isFirst && !isLast },
          { [classes.rootLast]: isLast && !isFirst }
        )}
      >
        <div className={classes.top} data-is-active={isActive}>
          <div className={classes.topActive}>{this.renderActiveIcon()}</div>
          <div className={classes.topCount}>{count(link.height)}</div>
          <div className={classes.topWhat}>{this.renderWhat()}</div>
        </div>
        <div
          className={classes.middle}
          data-is-first={isFirst}
          data-is-last={isLast}
        >
          <div className={classes.middleInterval}>{this.renderInterval()}</div>
          <div className={classes.middleAction}>{this.renderActionIcon()}</div>
        </div>
        <div className={classes.bottom} data-is-active={isActive}>
          <div className={classes.bottomWhen}>
            {shortFormat(link.createdAt)}
          </div>
          <div className={classes.bottomWho}>{group.name}</div>
          <div className={classes.bottomAvatar}>
            <div className={classes.avatarContainer} data-is-active={isActive}>
              <AvatarGroup size={36} src={group.avatar} />
            </div>
          </div>
          {hasAnswer && (
            <div className={classes.numberOfAnswers}>
              <span>{`${answersCount} answer${
                answersCount > 1 ? 's' : ''
              }`}</span>
            </div>
          )}
        </div>
      </div>
    );
  };
}

export default injectSheet(styles)(Segment);
